<template>
  <div class="mx-16">
    <div
      v-for="row_index in 3"
      :key="row_index"
    >
      <v-skeleton-loader 
        type="image" 
        height="72px"
        class="mb-8"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'DateItemsSkeleton'
}
</script>