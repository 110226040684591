<template>
  <div class="date-empty-placeholder">
    <p class="text-gray text-center">{{ $translate('my-calendar_date-empty') }}</p>
  </div>
</template>

<script>
export default {
  name: 'DateEmptyPlaceholder'
}
</script>
<style lang="scss">
.date-empty-placeholder{
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>