<template>
  <div
    class="add-meeting-btn"
    @click="$store.dispatch('layout/showMyCalendarModal')"
    v-ripple 
  >
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-8'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.5 6h10m-5-5v10" stroke="#8E8E93" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>
    <p class="small">{{ $translate('my-calendar_add-meeting') }}</p>
  </div>
</template>

<script>
export default {
  name: 'AddMeetingBtn',
}
</script>
<style lang="scss">
.add-meeting-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 8px 16px 0;
  padding: 20px 16px;
  border: 1px solid #F3F3F3;
  background: #FFF;
  color: #8E8E93;
  border-radius: 12px;
}
</style>