<template>
  <div
    class="week-calendar__list"
  >
    <router-link
      v-for="(meeting, k) in meetings"
      :key="k"
      class="week-calendar__list-item"
      replace
      :to="{ 
        name: 'FormPage',
        params: {
          form_data: meeting.meeting_desc_form.form,
          backToPath: $route.fullPath,
        },
      }" 
    >
      <div class="week-calendar__list-item__left">
        <p class="small-medium">
          <span
            v-if="!meeting.accepted" 
            class="list-item__dot"
          ></span>
          {{ meeting.subject }}
        </p>
        <p class="caption text-gray">{{ $translate('my-calendar_date-item_time') }} {{ meeting.start_time }} - {{ meeting.end_time}}</p>
      </div>
      <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'week-calendar__list-item__right-svg'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".2" d="M1 11l5-5-5-5" stroke="#111114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'DateItemsList',
  props: {
    meetings: {
      type: Array,
      required: true,
      validator(value) {
        return value.length > 0;
      }
    }
  },
}
</script>

<style lang="scss">
.week-calendar__list{
  margin: 0 16px;
  margin-top: 8px;
}
.week-calendar__list-item{
  display: flex;
  align-items: center;
  margin: 0 0 8px;
  padding: 14px 16px;
  background: #FFFFFF;
  border-radius: 12px;
  &__left {
    min-width: 0;
    margin-right: 4px;
    .list-item__dot{
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: auto 4px auto auto;
      background: #EF7F1A;
      border-radius: 50px;
    }
  }
  &__right-svg {
    margin-left: auto;
    margin-right: 8px;
  }
}
</style>