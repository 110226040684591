<template>
  <router-link
    class="my-calendar-modal__link"
    :to="{
      name: 'FormPage',
      params: {
        form_name: form_name,
        backToPath: $route.path,
      },
    }"
    replace
  >
    <div class="my-calendar-modal__link__left">
      <p class="small-medium">{{ title }}</p>
    </div>
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'my-calendar-modal__link__right-svg'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".2" d="M1 11l5-5-5-5" stroke="#111114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
  </router-link>
</template>

<script>
export default {
  name: 'MyCalendarModalLink',
  props: {
    title: {
      type: String,
      required: true
    },
    form_name: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="scss">
.my-calendar-modal__link{
  display: flex;
  align-items: center;
  margin: 0 0 8px;
  padding: 14px 16px;
  background: #F6F6F6;
  border-radius: 12px;
  &__left {
    min-width: 0;
    margin-right: 4px;
  }
  &__right-svg {
    margin-left: auto;
    margin-right: 8px;
  }
}
</style>