<template>
  <div 
    class="calendar-footer"
    :style="{
      paddingTop: height+'px',
    }"
  >
    <div 
      ref="content"
      class="calendar-footer__content"
    >
      <FavouriteTogglerRow 
        :menu_id="menu_id"
      />
    </div>
  </div>
</template>

<script>
import FavouriteTogglerRow from "@/components/reusable/FavouriteTogglerRow";
export default {
  name: 'MyCalendarFooter',
  props: ['menu_id'],
  data(){
    return {
      height: 0,
    }
  },
  components: {
    FavouriteTogglerRow
  },
  mounted(){
    this.$nextTick(() => {
      this.height = this.$refs.content?.offsetHeight || 0;
    })
  }
}
</script>

<style lang="scss">
.calendar-footer{
  &__content{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 8px 16px;
    background-color: #FFF;
    @supports(padding: max(0px)) {      // check if ios device 
      padding-bottom: calc(8px + env(safe-area-inset-bottom, 0px));
    }
  }
}
</style>