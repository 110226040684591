<template>
  <div class="date-slider">
    <div 
      v-for="(date) in datesList"
      :key="date.valueOf()"
      class="date-item"
      :class="{
        'date-item--active': isActiveDate(date),
        'date-item--loading': loading && !isActiveDate(date),
      }"
      @click="setActiveDate(date)"
    >
      <div class="date-item__content">
        <p class="caption date-item__weekday">{{ weekDay(date) }}</p>
        <p 
          class="small-medium text-center"
          :class="{
            'text-gray-light': isWeekend(date),
          }"
        >{{ monthDay(date) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { serverFormatedDate } from "@/helpers/utils.js";
import { mapState } from "vuex";
export default {
  name: 'CalendarDateSlider',
  props: ['activeDate', 'loading'],
  data(){
    let now = Date.now();
    let weekDay = new Date(now).getDay();
    let datesList = Array.from(
      { length: 14 }, 
      (date, index) => (new Date(now.valueOf()+864E5*(index - weekDay + 1)))
    );
    return {
      datesList
    }
  },
  computed:{
    ...mapState('layout', ['language'])
  },
  methods: {
    setActiveDate(date){
      if (!this.loading){
        this.$router.replace({
          query: {
            date: serverFormatedDate(date)
          }
        }).catch((error) => {
          if (error.name === 'NavigationDuplicated') return;
          throw error;
        });
      }
    },
    isActiveDate(date){
      return this.activeDate === serverFormatedDate(date)
    },
    weekDay(date){
      let locale = `${this.language.toLowerCase()}-${this.language.toUpperCase()}`//'kk-KK'
      return date.toLocaleDateString(locale, { weekday: 'short' });
    },
    monthDay(date){
      return date.getDate();
    },
    isWeekend(date){
      return [0, 6].includes(date.getDay());
    },
  },
}
</script>
<style lang="scss">
.date-slider{
  display: block;
  overflow-x: scroll;
  padding: 0 8px;
  white-space: nowrap;
  .date-item{
    display: inline-block;
    user-select: none;
    color: #8897A5;
    border-radius: 12px;
    margin: 0 8px 10px;
    background: #F3F3F3;
    .date-item__content{
      margin: 8px 20px;
    }
    .date-item__weekday{
      text-align: center;
      color: #8E8E93;
      margin: 0 0 4px;
    }
    &.date-item--loading{
      color: #8897A5;
      .date-item__weekday{
        text-align: center;
        color: #8897A5;
      }
    }
  }
  .date-item--active{
    background: #FFF;
    color: #151C22;
    border: 1px solid #EF7F1A;
    &.date-item--loading{
      background: #F6F6F6;
      color: #8897A5;
    }
  }
}
</style>