<template>
  <div 
    class="layout__body"
    :class="{
      'layout__body--error-placeholder': error,
    }"
  >    
    <DateSlider 
      :loading="loading"
      :active-date="activeDate"
    />
    <DateItemsSkeleton 
      v-if="loading" 
    />
    <ErrorPlaceholder
      v-else-if="error"
      :error="error"
      @retry="() => getCalendarEvents(activeDate)"
    />
    <template v-else-if="meetings">
      <AddMeetingBtn />
      <DateEmptyPlaceholder 
        v-if="meetings.length === 0" 
      />
      <DateItemsList 
        v-else
        :meetings="meetings"
      />
    </template>
    <Fullscreen
      v-if="my_calendar_modal"
      @clear="$store.dispatch('layout/hideMyCalendarModal')"
      :has-x-close="true"
    >
      <p class="mx-16 mb-8">{{ $translate('my-calendar_choose-meeting-type-title') }}</p>
      <ModalLink 
        v-for="(link, k) in fullscreenLinks"
        :key="k"
        :title="link.title"
        :form_name="link.form_name"
      />
    </Fullscreen>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import baseApi from "@/helpers/baseApi";
import backNavMixin from "@/mixins/backNav";
import AddMeetingBtn from "@/components/MyCalendar/AddMeetingBtn"
import DateEmptyPlaceholder from '@/components/MyCalendar/DateEmptyPlaceholder';
import DateItemsList from '@/components/MyCalendar/DateItemsList.vue';
import DateItemsSkeleton from '@/components/MyCalendar/DateItemsSkeleton.vue';
import DateSlider from '@/components/MyCalendar/DateSlider';
import ErrorPlaceholder from "@/components/reusable/ErrorPlaceholder";
import Fullscreen from "@/components/reusable/Fullscreen.vue";
import ModalLink from '@/components/MyCalendar/ModalLink';
import { serverFormatedDate } from "@/helpers/utils.js";

export default {
  name: 'WeekCalendar',
  components: { 
    AddMeetingBtn,
    DateEmptyPlaceholder,
    DateItemsList,
    DateItemsSkeleton,
    DateSlider,
    ErrorPlaceholder,
    Fullscreen,
    ModalLink,
  },
  props: {
    activeDate: {
      default: serverFormatedDate(new Date(Date.now()))
    },
  },
  mixins: [ backNavMixin ],
  data(){
    return {
      loading: true,
      error: undefined,
      meetings: undefined,
      fullscreenLinks: [
        {
          title: this.$translate('my-calendar_choose-meeting-type-single'),
          form_name: 'create_meeting_form',
        },
        {
          title: this.$translate('my-calendar_choose-meeting-type-regular'),
          form_name: 'create_regular_form',
        }
      ],
    }
  },
  computed: {
    ...mapState('layout', [
      'header_height', 
      'my_calendar_modal'
    ]),
  },
  watch: {
    activeDate: {
      immediate: true,
      handler(newDate){
        this.getCalendarEvents(newDate);
      }
    }
  },
  mounted(){
    this.$store.dispatch('layout/setHeaderTitle', this.$translate('title_my-calendar'));
  },
  destroyed(){
    this.$store.dispatch('layout/hideMyCalendarModal');
  },
  methods: {
    
    hasEvents(row){
      return row?.events && row.events.length > 0
    },
    
    async getCalendarEvents(date){
      this.loading = true;
      this.error = undefined;
      try {
        let res = await baseApi.get('api/form/my_calendar_form', {
          ...(date ? { params: { date }} : {}),
        })
        this.meetings = res?.data?.meetings;
      } catch (err) {
        this.error = err;
      }
      this.loading = false;
    }
  },

}
</script>